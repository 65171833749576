<template>
  <b-card
    class="warehousestatementrule-edit-wrapper"
  >
    <!-- form -->
    <b-form id="warehousestatementruleForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="费用科目 sales_cost_type"
            label-for="cost_type"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="cost_type"
              size="sm"
              v-model="warehousestatementrule.cost_type"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="费用科目扣费类型 sales_cost_charge_type"
            label-for="charge_type"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="charge_type"
              size="sm"
              v-model="warehousestatementrule.charge_type"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="当扣费类型选择月/年固定值时，表头显示金额，当扣费类型选择按销售额比例时，表头显示百分比 12%填0.12"
            label-for="rule_amount"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="rule_amount"
              size="sm"
              v-model="warehousestatementrule.rule_amount"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="是否品牌必选"
            label-for="is_brand"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="is_brand"
              size="sm"
              v-model="warehousestatementrule.is_brand"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="品牌Id"
            label-for="brand_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="brand_id"
              size="sm"
              v-model="warehousestatementrule.brand_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="备注"
            label-for="remark"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="remark"
              size="sm"
              v-model="warehousestatementrule.remark"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="附件"
            label-for="attachments"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="attachments"
              size="sm"
              v-model="warehousestatementrule.attachments"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="状态"
            label-for="state"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="state"
              size="sm"
              v-model="warehousestatementrule.state"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="创建时间"
            label-for="add_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="add_time"
              size="sm"
              v-model="warehousestatementrule.add_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="创建人"
            label-for="creator"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="creator"
              size="sm"
              v-model="warehousestatementrule.creator"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="修改时间"
            label-for="modify_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="modify_time"
              size="sm"
              v-model="warehousestatementrule.modify_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="修改人"
            label-for="updator"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="updator"
              size="sm"
              v-model="warehousestatementrule.updator"
            />
          </b-form-group>
        </b-col>
                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import warehousestatementruleStore from '@/views/apps/warehousestatementrule/warehousestatementruleStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      warehousestatementrule: ref({}),
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('warehousestatementrule')) store.registerModule('warehousestatementrule', warehousestatementruleStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('warehousestatementrule')) store.unregisterModule('warehousestatementrule')
    })

    const edit = function() {
      store.dispatch('warehousestatementrule/edit', {id: this.id}).then(res => {
        this.warehousestatementrule = res.data.data
      })
    }

    const view = function() {
      store.dispatch('warehousestatementrule/view', {id: this.id}).then(res => {
        this.warehousestatementrule = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      store.dispatch('warehousestatementrule/save', this.warehousestatementrule).then(res => {
        toast.success('数据已保存!')
        this.$router.push({ name: 'apps-${classVar}-list'});
      })
    }

    return {
      edit,
      view,
      cancel,
      save,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
